$(function() {
	$("#listingTags").tagit();
});

$(function() {
  function suggestTags() {
    console.log("suggesting tags");
    const tagElement = $("#listingTags");

    if (tagElement) {
      tagElement.tagit("removeAll");
      const titleQuery = document.getElementById('listing_locations_form_listing_attributes_title').value;
      // const contentQuery = document.getElementById('listing_content').value;

      let myData = {tag_query: `${titleQuery}`};
      // let myData = {tag_query: `${titleQuery} ${contentQuery}`};
      console.log("query is", myData)
      const pathname = window.location.pathname;
      const language = pathname.split("/")[1];

      $.ajax({
        url: '/'+language+'/tags/suggest_tags',
        type: "get",
        data: myData,
      }).done(function(data) {
        if (data && data.suggestedTags) {
          data.suggestedTags.forEach(el => {
            tagElement.tagit('createTag', el);
          });
        }
      }).fail(function() {
        alert('An error occured in suggesting process.');
      }).always(function() {
        console.log('Complete');
      }); //ajax end
    } // tagElement check end
  } // suggest Tag end

  $("#listing_locations_form_listing_attributes_title").change(function () {
    suggestTags();
  });

  // $("#listing_content").change(function () {
  //   suggestTags();
  // });

});
