import consumer from "./consumer"
import CableReady from 'cable_ready'

consumer.subscriptions.create("NotificationChannel", {
  connected() {
    console.log("conneected cable ready")
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    console.log("disconnected")
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log("receviveddddd"+data.operations)
    // Called when there's incoming data on the websocket for this channel
     if (data.cableReady) CableReady.perform(data.operations)
  }
});
