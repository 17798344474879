// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'channels';
import 'jquery';
import 'jquery-ui';
import 'popper.js'; // Must be imported after jquery but before bootstrap
import 'bootstrap';
import 'dropzone';
import 'dropzone/dist/basic.css';
import 'dropzone/dist/dropzone.css';
import '@googlemaps/markerclusterer'
// import 'feather-icons';
// import './assets/3rdParty/tag-it/tag-it.js'; // Removed as tag-it.min.js already being imported
import './assets/3rdParty/tag-it/tag-it.min.js';
import './assets/js/tag-it-custom.js';
import './assets/js/plab-custom';
import './assets/js/dropzone-custom.js';
import './assets/js/square_payments';
import './assets/js/paypal_payments';
import './assets/js/custom-function.js';
import './assets/js/googlemap-custom.js';
import './assets/js/leafletmap-custom.js';
import './assets/js/lazyload.js';
// import 'packs/rails-assets/apexcharts';
import './assets/stylesheets/style.scss';
import './assets/3rdParty/icofont/icofont.min.css';
import './assets/3rdParty/lineIcons/lineIcons.css';
import './assets/3rdParty/viewer/viewer.min.css';
import './assets/3rdParty/viewer/viewer.min.js';

// TODO: @rails/ujs had moved to in to rails https://github.com/rails/rails-ujs since Rails 5.1.0
// However, since rails 7 guidelines recommend using Turbo instead:
// https://edgeguides.rubyonrails.org/working_with_javascript_in_rails.html
import * as Ujs from '@rails/ujs';
Ujs.start();

// TODO: turbolinks is no longer under active development and has been superseded by Turbo https://github.com/hotwired/turbo
// Project needs upgrading.
import * as Turbolinks from 'turbolinks';
Turbolinks.start();

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
