export default {
  leafletMapZoom: 14,
  leafletMapMaxZoom: 17,
  leafletMapMinZoom: 3,
  leafletCircleColor: "#FF0000",
  leafletCircleOpacity: 0.35,
  leafletCircleRadius: 150,
  leafletCircleZoomThreshold: 13,
  leafletPopupMaxWidth: 200,
}
