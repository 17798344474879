import apiCall from "../../react/utils/apiHelper";
import I18n from "../../utils/i18n";

const t = (key) => new I18n(window.rails_I18n).t(key);

document.addEventListener('DOMContentLoaded', async function () {
  if (!window.paypal) {
    return; // ends function
  }

  const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
  const getFormData = () => {
    const batchAmount = document.getElementById('coin_gain_batch_amount').value;
    const fees = document.getElementById('coin_gain_fees').value;
    const totalAmount = document.getElementById('coin_gain_total_amount').value;
    const paymentMethod = document.getElementById('coin_gain_payment_method').value;

    if (!batchAmount | !fees | !totalAmount | !paymentMethod) {
      throw new Error('Missing form values!');
    }

    return {
      batchAmount,
      fees,
      totalAmount,
      paymentMethod
    }
  }

  // Render the PayPal button into #paypal-button-container
  paypal.Buttons({
    // Call your server to set up the transaction
    createOrder: async function(data, actions) {
      try {
        const { batchAmount, fees, totalAmount, paymentMethod } = getFormData();
        if (batchAmount === '0') {
          return; //PENDING to return without error in the console
        }
        const result = await apiCall(
          "POST",
          '/wallets/gains/paypal_order_create',
          csrfToken,
          {coin_gain: { 
            batch_amount: batchAmount, 
            fees: fees, 
            total_amount: totalAmount, 
            payment_method: paymentMethod
          }}
        );

        if (!result && !result.id){
          throw new Error('No paypal_order_create result!');
        }

        return result.id;
      } catch (err) {
        console.log('ERROR: ', err);
      } finally {
        //Do something
      }
    },

    // Call your server to finalize the transaction
    onApprove: async function(data, actions) {
      const { batchAmount, fees, totalAmount, paymentMethod } = getFormData();
      const capturePaypalOrder = async (orderID) => {
        const result = await apiCall(
          "POST",
          `/wallets/gains/paypal_order_capture?order_id=${orderID}`,
          csrfToken,
          {coin_gain: { 
            batch_amount: batchAmount, 
            fees: fees, 
            total_amount: totalAmount, 
            payment_method: paymentMethod
          }}
        );

        if (!result) {
          throw new Error('No paypal_order_capture api result');
        }
        // Three cases to handle:
        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        //   (2) Other non-recoverable errors -> Show a failure message
        //   (3) Successful transaction -> Show confirmation or thank you

        // This example reads a v2/checkout/orders capture response, propagated from the server
        // You could use a different API or structure for your 'result'
        const errorDetail = Array.isArray(result.details) && result.details[0];

        if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
          return actions.restart(); // Recoverable state, per:
          // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
        }

        if (errorDetail) {
          let msg = 'Sorry, your transaction could not be processed.';
          if (errorDetail.description) msg += '\n\n' + errorDetail.description;
          if (result.debug_id) msg += ' (' + result.debug_id + ')';
          return alert(msg); // Show a failure message (try to avoid alerts in production environments)
        }

        const element = document.getElementById('paypal-button-container');
        element.innerHTML = '<p class="alert alert-success">' + t('paypal.charge_completed') + '</p>';

        setTimeout(function(){
          window.location.href = "/wallets";
        }, 2000);
      }

      await capturePaypalOrder(data.orderID);
    }
  }).render('#paypal-button-container');
});

