import I18n from "../../utils/i18n";
import heic2any from "heic2any";

const t = (key) => new I18n(window.rails_I18n).t(key);

Dropzone.options.myPrettifiedDropzone = {
  addRemoveLinks: true,
  acceptedFiles: ".jpeg, .jpg, .png, .tif, .heic, .heif",
  maxFiles: 20,
  maxThumbnailFilesize: 5,
  maxFilesize: 5,
  dictDefaultMessage: t('dropzone.drop_image'),
  dictRemoveFile: t('dropzone.remove_image'),
  dictCancelUpload: t('dropzone.cancel_upload'),

  init: function() {
    let convertHeicImage = function(blobURL, file){
      fetch(blobURL)
        .then((res) => res.blob())
        .then((blob) => heic2any({
          blob,
          quality: 0.1,
          toType: 'image/jpeg'
        }))
        .then((conversionResult) => {
          let url = URL.createObjectURL(conversionResult);
          for (let thumbnailElement of file.previewElement.querySelectorAll(
            "[data-dz-thumbnail]"
          )) {
            thumbnailElement.alt = file.name;
            thumbnailElement.src = url;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    this.on("success", function(file) {
      let blobURL = URL.createObjectURL(file);
      if (!file.type.startsWith("image/")) {
        convertHeicImage(blobURL, file)
      }
    });
    this.on("error", (file, message) => {
      if (file.previewElement) {
        file.previewElement.classList.add("dz-error");
        if (typeof message !== "string" && message.error) {
          message = message.error;
        }
        for (let node of file.previewElement.querySelectorAll(
          "[data-dz-errormessage]"
        )) {
          if (file.xhr && file.xhr.status == "304") {
            node.textContent = t('dropzone.number_of_images_exceeded_limit');
          } else {
            node.textContent = message;
          }

        }
      }
    });
    this.on("removedfile", file => {
      const responseData = file.xhr.responseText;
      const jsonData = JSON.parse(responseData);
      let myData = {image_key: jsonData.imageKey};
      let myPath = window.location.pathname;
      let myUrl;
      if (myPath.includes("/listings/new")) {
        myUrl = "/listings/remove_photos_new";
      } else if (myPath.includes("/gallery")){
        myUrl = "/gallery/remove_photos"
      } else if (myPath.includes("/support")){
        myUrl = "/support/remove_photos"
      } else {
        myUrl = myPath + "/remove_photos_show";
      }
      $.ajax({
        type: "DELETE",
        url: myUrl,
        data: myData,
      }).done(function() {
        // alert('Image was successfully removed.')
      }).fail(function() {
        alert('An error occured in removal process.');
      }).always(function() {
        // console.log('Complete');
      })
    });
  }
}
