
function I18n(dictionary = undefined){
  /**
   * @param {string} key
   * @param {object | null} options
   * @returns {string}
   */
  this.t = function(key, options = null){
    let result;
    // check there is a dictionary
    if(!dictionary){
      return key
    }

    // check key is string
    if(typeof key !== 'string' || !key instanceof String){
      throw new Error('Key is not a string!')
    }

    const keyArr = key.split('.');
    // check keyArr is greater th
    if(keyArr.length === 1){
      throw new Error('Key is not valid!')
    }
    const ns = keyArr[0];
    // check dictionary for ns
    if(!Object.prototype.hasOwnProperty.call(dictionary, ns)){
      throw new Error('Keys NS does not exist in dictionary!')
    }

    const id = keyArr[1];
    // check dictionary[ns] for id
    if(!Object.prototype.hasOwnProperty.call(dictionary[ns], id)){
      throw new Error('Keys ID does not exist in dictionary!')
    }

    if(keyArr.length === 2){
      result = dictionary[ns][id];
    }

    if(keyArr.length === 3){
      if(!Object.prototype.hasOwnProperty.call(dictionary[ns][id], keyArr[2])){
        throw new Error('Keys ID2 does not exist in dictionary!')
      }
      result = dictionary[ns][id][keyArr[2]];
    }

    if(keyArr.length === 4){
      if(!Object.prototype.hasOwnProperty.call(dictionary[ns][id][keyArr[2]], keyArr[3])){
        throw new Error('Keys ID3 does not exist in dictionary!')
      }
      result = dictionary[ns][id][keyArr[2]][keyArr[3]];
    }

    if(keyArr.length > 4) {
      throw new Error('Key is not valid, its too deep!')
    }

    if (options) {
      // check options is an object
      if(typeof options !== 'object' && Object.keys(options).length > 0){
        throw new Error('Options are not valid!')
      }
      return Object.keys(options).reduce(
          (accumulator, key) => accumulator.replace(`{${key}}`, options[key]),
          result
      );
    }

    return result;
  }
}


export default I18n;
