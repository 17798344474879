import L from "leaflet";
import "leaflet.markercluster";
import customConstants from "../../utils/customConstants";
import I18n from "../../utils/i18n";
import LazyLoad from "vanilla-lazyload";

const t = (key) => new I18n(window.rails_I18n).t(key);

//Leaflet map to show fuzzy locations
window.leafletMapShow = function(locations, fuzzyRadius, select){
  const map = L.map('leaflet-map', {dragging: !L.Browser.mobile}).setView([locations[0][1], locations[0][2]], customConstants.leafletMapZoom);
  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: customConstants.leafletMapMaxZoom,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);

  let marker, popup, i, fuzzyCircle, fuzzyPos;
  let bounds = [];
  let markers = {};

  for (i = 0; i < locations.length; i++) {  
    fuzzyPos = [locations[i][1], locations[i][2]]; 
    bounds.push(fuzzyPos);
    marker = L.marker(fuzzyPos).addTo(map)
    marker.dataset = {markerId: i};
    if (select){
      marker.on('click', function(e) {
        // Use the event to find the clicked element
        const markerId = e.target.dataset.markerId;
				const el = document.getElementById('location_id')
				if (el){
					el.value = locations[parseInt(markerId)][3]; 
        }
			});
		}

    if (locations[i][4]){
      popup = L.popup({
        maxWidth: customConstants.leafletPopupMaxWidth
      })
        .setContent(
          '<div class="gmap-infowindow">' +
          `<p class="">${locations[i][0]}</p>` +
          `<a data-turbolinks="false" href="${locations[i][4]}">` +
          `${t('leafletmap.see_availabilities')}` +
          '</a>' +
          '</div>'
        );
      marker.bindPopup(popup).closePopup();
    } else {
      marker.bindPopup(locations[i][0]).closePopup();
    }

    if (fuzzyRadius && !locations[i][5]) {
      fuzzyCircle = L.circle(fuzzyPos, {
        color: customConstants.leafletCircleColor,
        fillColor: customConstants.leafletCircleColor,
        fillOpacity: customConstants.leafletCircleOpacity,
        radius: fuzzyRadius 
      }).addTo(map);
    }
  }

  if(locations.length > 1){
    map.fitBounds(bounds, {maxZoom: 16});
  }
}

//Leaflet map to show shops with clusters 
window.leafletShops = function(locations, centerLatLng, zoomGmap){
  const map = L.map('leaflet-map', {dragging: !L.Browser.mobile}).setView([centerLatLng.lat, centerLatLng.lng], zoomGmap);
  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: customConstants.leafletMapMaxZoom,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);
  const lazyLoadInstance = new LazyLoad({
  });

  // Add some markers to the map.
  const markers = L.markerClusterGroup();
  let bounds = [];
  let marker, popup;
  locations.map((position, i) =>{
    marker = L.marker([position.lat, position.lng]);
    popup = L.popup({
      maxWidth: customConstants.leafletPopupMaxWidth
    })
      .setContent(
        `<a data-turbolinks="false" href="${position.link}">` +
        '<div class="gmap-infowindow">' +
        position.image +
        `<h6 class="">${position.headline}</h6>` +
        '</div>' +
        '</a>'
      );
    marker.bindPopup(popup).closePopup();

    //Custom function to initiate additional lazyload for the popup avatar image after the marker click.
    marker.on('popupopen', function() {
      lazyLoadInstance.update();
    });

    markers.addLayer(marker);
    bounds.push([position.lat, position.lng]);
  });
  map.addLayer(markers);

  if(locations.length > 1){
    map.fitBounds(bounds, {maxZoom: 16});
  }
}

//Leaflet map to show shops with clusters 
window.leafletListings = function(locations, centerLatLng, zoomGmap){
  const map = L.map('leaflet-map', {dragging: !L.Browser.mobile}).setView([centerLatLng.lat, centerLatLng.lng], zoomGmap);
  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: customConstants.leafletMapMaxZoom,
    minZoom: customConstants.leafletMapMinZoom,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);

  // Add some markers to the map.
  const markers = L.markerClusterGroup();
  let bounds = [];
  let circles = [];
  let i, marker, fuzzyCircle;
  for (i = 0; i < locations.length; i++) {  
    marker = L.marker([locations[i].lat, locations[i].lng]);
    marker.dataset = {markerId: locations[i].id};
    marker.on('click', function(e) {
      $(document).ready(function(){
        const markerId = e.target.dataset.markerId;
        $(`#ListingsModal-${markerId}`).modal("show");
      });
    });

    markers.addLayer(marker);
    bounds.push([locations[i].lat, locations[i].lng]);

    if (!locations[i].expose_loc) {
      fuzzyCircle = L.circle([locations[i].lat, locations[i].lng], {
        color: customConstants.leafletCircleColor,
        fillColor: customConstants.leafletCircleColor,
        fillOpacity: customConstants.leafletCircleOpacity,
        radius: customConstants.leafletCircleRadius 
      });
      circles.push(fuzzyCircle);
    }
  };
  map.addLayer(markers);

  //Adding circles if the zoom is larger and removing otherwise.
  map.on('zoomend', function (e) {
    if (e.target._zoom >= customConstants.leafletCircleZoomThreshold){
      circles.map((circle) => circle.addTo(map));
    } else {
      circles.map((circle) => circle.remove(map));
    }
  });

  if(locations.length > 1){
    map.fitBounds(bounds, {maxZoom: 16});
  }
}

window.toggleCheckbox = function (element){
  let el;
  el = document.getElementById(element);
  el.checked = !el.checked;
}

//Leaflet map to update sellers sales sites at public stores 
window.leafletUpdateSites = function(locations, centerLatLng, zoomGmap){
  const map = L.map('leaflet-map', {dragging: !L.Browser.mobile}).setView([centerLatLng.lat, centerLatLng.lng], zoomGmap);
  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: customConstants.leafletMapMaxZoom,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);

  // Add some markers to the map.
  const markers = L.markerClusterGroup();
  let bounds = [];
  let marker, popup, checkboxChecked;
  locations.map((position, i) =>{
    if (locations[i].checked){
      checkboxChecked = "checked='checked'" 
    } else {
      checkboxChecked = "" 
    }
    marker = L.marker([position.lat, position.lng]);
    popup = L.popup({
      maxWidth: customConstants.leafletPopupMaxWidth
    })
      .setContent(
        `<div class="form-group row">` +
        `<div class="form-check">` +
        `<input class="form-check-input leaflet" type="checkbox" ${checkboxChecked} onchange="toggleCheckbox('seller_locations_${locations[i].id}')" id="leaflet_checkbox_${locations[i].id}">` +
        `<label class="form-check-label leaflet" for="leaflet_checkbox_${locations[i].id}">${t('leafletmap.select')}</label>` +
        '</div>' +
        '</div>' +
        `<a data-turbolinks="false" href="${position.link}">` +
        '<div class="gmap-infowindow">' +
        position.image +
        `<h6 class="">${position.headline}</h6>` +
        '</div>' +
        '</a>'
      );
    marker.bindPopup(popup).closePopup();
    markers.addLayer(marker);
    bounds.push([position.lat, position.lng]);
  });
  map.addLayer(markers);

  if(locations.length > 1){
    map.fitBounds(bounds, {maxZoom: 16});
  }
}

//Leaflet map to update sellers sales sites at public stores 
window.leafletAddSites = function(locations, centerLatLng, zoomGmap){
  const map = L.map('leaflet-map', {dragging: !L.Browser.mobile}).setView([centerLatLng.lat, centerLatLng.lng], zoomGmap);
  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: customConstants.leafletMapMaxZoom,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);

  const lazyLoadInstance = new LazyLoad({
  });

  // Add some markers to the map.
  const markers = L.markerClusterGroup();
  let bounds = [];
  let marker, popup, checkboxChecked;
  locations.map((position, i) =>{
    if (locations[i].checked){
      checkboxChecked = "checked='checked'" 
    } else {
      checkboxChecked = "" 
    }
    marker = L.marker([position.lat, position.lng]);
    popup = L.popup({
      maxWidth: customConstants.leafletPopupMaxWidth
    })
      .setContent(
        `<div class="">` +
        `${locations[i].button}` +
        '</div>' +
        `<div class="">` +
        `<a data-turbolinks="false" href="${position.link}">` +
        '<div class="gmap-infowindow">' +
        position.image +
        `<h6 class="">${position.headline}</h6>` +
        '</div>' +
        '</a>'
      );
    marker.bindPopup(popup).closePopup();
    //Custom function to initiate additional lazyload for the popup avatar image after the marker click.
    marker.on('popupopen', function() {
      lazyLoadInstance.update();
    });

    markers.addLayer(marker);
    bounds.push([position.lat, position.lng]);
  });
  map.addLayer(markers);

  if(locations.length > 1){
    map.fitBounds(bounds, {maxZoom: 16});
  }
}




