import axios from "axios";

/**
 * apiCall
 *
 * @param {string} method - API Method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'.
 * @param {string} url - The API endpoint URL.
 * @param {string} csrfToken - csrfToken passed from page @example: const csrfToken = document.querySelector('[name=csrf-token]').content
 * @param {object} data - body object data
 * @param {boolean} isFile - If true, sets 'Content-Type' to multipart/form-data. Default is false as sets 'Content-Type' to application/json.
 * @param {function | null} onUploadProgress - onUploadProgress callback. Default = null
 * @returns {Promise<*>}
 *
 * @example: apiCall("POST", "availabilities/add_timings", document.querySelector('[name=csrf-token]').content, { new_timings: timings }).then((res) => console.log("Success",res)).catch((err) => console.log("Error", err))
 */
export default async function apiCall(
  method,
  url,
  csrfToken,
  data,
  isFile = false,
  onUploadProgress = null
) {
  const onSuccess = function (response) {
    return response.data;
  };
  const onError = function (error) {
    return Promise.reject(error.response?.data || error.message);
  };

  let headers;

  if (isFile) {
    headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "X-CSRF-TOKEN": csrfToken,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": csrfToken,
    };
  }
  return axios
    .request({
      method,
      url: url,
      data: data,
      headers: headers,
      responseType: "json",
      transformRequest: (data) => {
        const stringifyData = data ? JSON.stringify(data) : null;
        return isFile ? data : stringifyData;
      },
      onUploadProgress: onUploadProgress,
    })
    .then(onSuccess)
    .catch(onError);
}
