function geocodeAddress(geocoder, input, locale) {
  return new Promise((res, rej) => {
    geocoder
      .geocode( { 'address': input}, 
        function(results, status) {
          if (status == 'OK') {
            let resRevGeocode = "";
            revGeocodeLatLng(
              geocoder, 
              results[0].geometry.location,
              locale
            ).then(
              (val) => {
                res([results[0].geometry.location, val]);
              }
            );
          } else {
            alert('Geocode was not successful for the following reason: ' + status);
            rej(status);
          }
        });
  });
}

function revGeocodeLatLng(geocoder, input, locale) {
  return new Promise((res, rej) => {
    const latlng = {
      lat: parseFloat(input.lat()),
      lng: parseFloat(input.lng()),
    };

    let resAddress = "";
    let placeId = "";
    let resFuzzyAddress = "";

    geocoder
      .geocode({ location: latlng })
      .then((response) => {
        if (response.results[0]) {
          resAddress = response.results[0].formatted_address;
          placeId = " (" + response.results[0].place_id.slice(-3) +")";
          // Construct fuzzy address with three geo components
          console.log("address is ", response.results[0].address_components);
          if (response.results[0].address_components.length >= 6) {
            resFuzzyAddress = response.results[0].address_components.slice(-5, -2);
          } else if (response.results[0].address_components.length = 5) {
            resFuzzyAddress = response.results[0].address_components.slice(1, -2);
          } else if (response.results[0].address_components.length >= 2) {
            resFuzzyAddress = response.results[0].address_components.slice(1, -1);
          } else {
            resFuzzyAddress = response.results[0].address_components;
          }
          if (locale == 'ja') {
            resFuzzyAddress = resFuzzyAddress.map(obj => obj.long_name).reverse().join('') + placeId;
          } else {
            resFuzzyAddress = resFuzzyAddress.map(obj => obj.long_name).join(' ') + placeId;
          }
        } else {
          window.alert("No results found");
        }
        res([resAddress, resFuzzyAddress]);
      })
      .catch((e) => {
        window.alert("Geocoder failed due to: " + e);
        rej(e);
      });
  })
}

//Responsive multiple gmap input for updating locations
window.gmapProcess = function (locLat, locLng, index, idName, locale) {
  // Set a default location as initialLatLng
  const initialLatLng = { lat: locLat, lng: locLng };

  const initialAddress = document.getElementById(`${idName}_address`).value;
  const map = new google.maps.Map(
    document.getElementById(`map-${index}`), {
      zoom: 10,
      center: initialLatLng,
      streetViewControl: false,
    });
  let marker = new google.maps.Marker({
    position: initialLatLng,
  });
  let geocoder = new google.maps.Geocoder();

  marker.setMap(map);

  //Getting latLng and address from clicking map
  map.addListener("click", (mapsMouseEvent) => {
    marker.setMap(null);
    marker = new google.maps.Marker({
      position: mapsMouseEvent.latLng,
    });
    marker.setMap(map);
    document
      .getElementById(`${idName}_latitude`)
      .value = mapsMouseEvent.latLng.lat();
    document
      .getElementById(`${idName}_longitude`)
      .value = mapsMouseEvent.latLng.lng();
    revGeocodeLatLng(
      geocoder, 
      mapsMouseEvent.latLng,
      locale
    ).then(
      (val) => {
        document
          .getElementById(`${idName}_address`)
          .value = val[0];
        document
          .getElementById(`${idName}_google_address`)
          .value = val[0];
        document
          .getElementById(`${idName}_fuzzy_address`)
          .value = val[1];
      }
    );
  });

  //Wait 3 seconds to reflect the address change to the map
  const inputField = document.getElementById(`${idName}_address`);
  let timer;

  function handleTyping() {
    clearTimeout(timer); // Clear any existing timer.
    timer = setTimeout(fireEvent, 3000); // Set a new timer for 3 seconds.
  }

  function fireEvent() {
    //Getting latLng and maker postion from address input
    const resLocation = geocodeAddress(
      geocoder, 
      inputField.value,
      locale,
    ).then(
      (val) => val
    ).catch((err) => console.log("geocodeAddress error: ", err)
    );

    resLocation
      .then((result) => {
        let lat =locLat;
        let lng = locLng;
        let pos = initialLatLng;
        let googleAddress = "";
        let fuzzyAddress = "";

        if (result) {
          lat = result[0].lat();
          lng = result[0].lng();
          pos = result[0];
          googleAddress = result[1][0];
          fuzzyAddress = result[1][1];
        } else {
          document
            .getElementById(`${idName}_address`)
            .value = initialAddress;
        }
        map.setCenter(pos);
        marker.setMap(null);
        marker = new google.maps.Marker({
          map: map,
          position: pos,
        });
        document
          .getElementById(`${idName}_latitude`)
          .value = lat;
        document
          .getElementById(`${idName}_longitude`)
          .value = lng;
        document
          .getElementById(`${idName}_google_address`)
          .value = googleAddress;
        document
          .getElementById(`${idName}_fuzzy_address`)
          .value = fuzzyAddress;
      }); 

  }

  inputField.addEventListener('input', handleTyping);

}
//End Process
